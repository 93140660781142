const methods = {
    fullSlug() {
        if (this.parent == null) {
            return this.slug
        }

        return `${this.parent.fullSlug()}/${this.slug}`
    },
    uri() {
        return `/${this.lang}/${this.fullSlug()}`
    },
}

// Create tags
const recettes = {
    slug: `recettes`,
    lang: `fr`,
}

const recipes = {
    slug: `recipes`,
    lang: `en`,
}

const voyages = exports.voyagesTag = {
    slug: `voyages`,
    lang: `fr`,
}

const travel = exports.travelTag = {
    slug: `travel`,
    lang: `en`,
}

const famille = {
    slug: `famille`,
    lang: `fr`,
}

const family = {
    slug: `family`,
    lang: `en`,
}

const accompagnements = {
    slug: `accompagnements`,
    lang: `fr`,
    parent: recettes,
}

const sides = {
    slug: `sides`,
    lang: `en`,
    parent: recipes,
}

const aixLesBainsFrance = {
    slug: `aix-les-bains-france`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 45.6924421,
        lng: 5.9082885,
    },
}

const aixLesBainsFrenchRepublic = {
    slug: `aix-les-bains-french-republic`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 45.6924421,
        lng: 5.9082885,
    },
}

const berneSuisse = {
    slug: `berne-suisse`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 46.9473201,
        lng: 7.4507187,
    },
}

const bernSwitzerland = {
    slug: `bern-switzerland`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 46.9473201,
        lng: 7.4507187,
    },
}

const boissons = {
    slug: `boissons`,
    lang: `fr`,
    parent: recettes,
}

const beverages = {
    slug: `beverages`,
    lang: `en`,
    parent: recipes,
}

const boulanges = {
    slug: `boulanges`,
    lang: `fr`,
    parent: recettes,
}

const breadsAndDough = {
    slug: `breads-dough`,
    lang: `en`,
    parent: recipes,
}

const budapestHongrie = {
    slug: `budapest-hongrie`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 47.4978408,
        lng: 19.0377614,
    },
}

const budapestHungary = {
    slug: `budapest-hungary`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 47.4978408,
        lng: 19.0377614,
    },
}

const chatelleraultFrance = {
    slug: `chatellerault-france`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 46.8136865,
        lng: 0.5454021,
    },
}

const chatelleraultFrenchRepublic = {
    slug: `chatellerault-french-republic`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 46.8136865,
        lng: 0.5454021,
    },
}

const entrees = {
    slug: `entrees`,
    lang: `fr`,
    parent: recettes,
}

const starters = {
    slug: `starters`,
    lang: `en`,
    parent: recipes,
}

const entreesFroides = {
    slug: `entrees-froides`,
    lang: `fr`,
    parent: entrees,
}

const coldStarters = {
    slug: `cold-starters`,
    lang: `en`,
    parent: starters,
}

const cracoviePologne = {
    slug: `cracovie-pologne`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 50.0642713,
        lng: 19.9426511,
    },
}

const cracowPoland = {
    slug: `cracow-poland`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 50.0642713,
        lng: 19.9426511,
    },
}

const desserts = {
    slug: `desserts`,
    lang: `fr`,
    parent: recettes,
}

const sweets = {
    slug: `sweets`,
    lang: `en`,
    parent: recipes,
}

const entreesChaudes = {
    slug: `entrees-chaudes`,
    lang: `fr`,
    parent: entrees,
}

const warmStarters = {
    slug: `warm-starters`,
    lang: `en`,
    parent: starters,
}

const plats = {
    slug: `plats`,
    lang: `fr`,
    parent: recettes,
}

const main = {
    slug: `main`,
    lang: `en`,
    parent: recipes,
}

const poissons = {
    slug: `poissons`,
    lang: `fr`,
    parent: plats,
}

const fish = {
    slug: `fish`,
    lang: `en`,
    parent: main,
}

const innsbruckAutriche = {
    slug: `innsbruck-autriche`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 47.2692124,
        lng: 11.4041024,
    },
}

const innsbruckAustria = {
    slug: `innsbruck-austria`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 47.2692124,
        lng: 11.4041024,
    },
}

const lyonFrance = {
    slug: `lyon-france`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 45.7578228,
        lng: 4.8350989,
    },
}

const lyonFrenchRepublic = {
    slug: `lyon-french-republic`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 45.7578228,
        lng: 4.8350989,
    },
}

const viandes = {
    slug: `viandes`,
    lang: `fr`,
    parent: plats,
}

const meat = {
    slug: `meat`,
    lang: `en`,
    parent: main,
}

const nimesFrance = {
    slug: `nimes-france`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 43.8360985,
        lng: 4.3588632,
    },
}

const nimesFrenchRepublic = {
    slug: `nimes-french-republic`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 43.8360985,
        lng: 4.3588632,
    },
}

const portoPortugal = {
    slug: `porto-portugal`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 41.1579438,
        lng: -8.6291053,
    },
}

const portoPortugueseRepublic = {
    slug: `porto-portuguese-republic`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 41.1579438,
        lng: -8.6291053,
    },
}

const poznanPologne = {
    slug: `poznan-pologne`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 52.4065036,
        lng: 16.9250000,
    },
}

const poznanPoland = {
    slug: `poznan-poland`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 52.4065036,
        lng: 16.9250000,
    },
}

const pragueRepubliqueTcheque = {
    slug: `prague-republique-tcheque`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 50.0755381,
        lng: 14.4378005,
    },
}

const pragueCzechRepublic = {
    slug: `prague-czech-republic`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 50.0755381,
        lng: 14.4378005,
    },
}

const saintGillesLaReunion = {
    slug: `saint-gilles-la-reunion`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: -21.0530861,
        lng: 55.2280047,
    },
}

const stGillesReunionIsland = {
    slug: `st-gilles-reunion-island`,
    lang: `en`,
    parent: travel,
    position: {
        lat: -21.0530861,
        lng: 55.2280047,
    },
}

const saintLeuLaReunion = {
    slug: `saint-leu-la-reunion`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: -21.1741396,
        lng: 55.2878123,
    },
}

const stLeuReunionIsland = {
    slug: `st-leu-reunion-island`,
    lang: `en`,
    parent: travel,
    position: {
        lat: -21.1741396,
        lng: 55.2878123,
    },
}

const saintPierreLaReunion = {
    slug: `saint-pierre-la-reunion`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: -21.333315,
        lng: 55.4722062,
    },
}

const stPierreReunionIsland = {
    slug: `st-pierre-reunion-island`,
    lang: `en`,
    parent: travel,
    position: {
        lat: -21.333315,
        lng: 55.4722062,
    },
}

const strasbourgFrance = {
    slug: `strasbourg-france`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 48.57415,
        lng: 7.750026,
    },
}

const strasbourgFrenchRepublic = {
    slug: `strasbourg-french-republic`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 48.57415,
        lng: 7.750026,
    },
}

const toulonFrance = {
    slug: `toulon-france`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 43.1244221,
        lng: 5.9300533,
    },
}

const toulonFrenchRepublic = {
    slug: `toulon-french-republic`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 43.1244221,
        lng: 5.9300533,
    },
}

const vegetarien = {
    slug: `vegetarien`,
    lang: `fr`,
    parent: plats,
}

const vegetarian = {
    slug: `vegetarian`,
    lang: `en`,
    parent: main,
}

const venissieuxFrance = {
    slug: `venissieux-france`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 45.6987738,
        lng: 4.8840546,
    },
}

const venissieuxFrenchRepublic = {
    slug: `venissieux-french-republic`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 45.6987738,
        lng: 4.8840546,
    },
}

const vienneAutriche = {
    slug: `vienne-autriche`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 48.2091696,
        lng: 16.3725936,
    },
}

const viennaAustria = {
    slug: `vienna-austria`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 48.2091696,
        lng: 16.3725936,
    },
}

const naplesItalie = {
    slug: `naples-italie`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 40.853586,
        lng: 14.1729668,
    },
}

const naplesItaly = {
    slug: `naples-italy`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 40.853586,
        lng: 14.1729668,
    },
}

const marseilleFrance = {
    slug: `marseille-france`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: 43.2908997,
        lng: 5.3503239,
    },
}

const marseilleFrenchRepublic = {
    slug: `marseille-french-republic`,
    lang: `en`,
    parent: travel,
    position: {
        lat: 43.2908997,
        lng: 5.3503239,
    },
}

const stSuzanneLaReunion = {
    slug: `sainte-suzanne-la-reunion`,
    lang: `fr`,
    parent: voyages,
    position: {
        lat: -20.950574,
        lng: 55.5585293,
    },
}

const stSuzanneReunionIsland = {
    slug: `st-suzanne-reunion-island`,
    lang: `en`,
    parent: travel,
    position: {
        lat: -20.950574,
        lng: 55.5585293,
    },
}

// Add localized version
recettes.localizedVersion = recipes
recipes.localizedVersion = recettes
voyages.localizedVersion = travel
travel.localizedVersion = voyages
famille.localizedVersion = family
family.localizedVersion = famille
accompagnements.localizedVersion = sides
sides.localizedVersion = accompagnements
aixLesBainsFrance.localizedVersion = aixLesBainsFrenchRepublic
aixLesBainsFrenchRepublic.localizedVersion = aixLesBainsFrance
berneSuisse.localizedVersion = bernSwitzerland
bernSwitzerland.localizedVersion = berneSuisse
boissons.localizedVersion = beverages
beverages.localizedVersion = boissons
boulanges.localizedVersion = breadsAndDough
breadsAndDough.localizedVersion = boulanges
budapestHongrie.localizedVersion = budapestHungary
budapestHungary.localizedVersion = budapestHongrie
chatelleraultFrance.localizedVersion = chatelleraultFrenchRepublic
chatelleraultFrenchRepublic.localizedVersion = chatelleraultFrance
entrees.localizedVersion = starters
starters.localizedVersion = entrees
entreesFroides.localizedVersion = coldStarters
coldStarters.localizedVersion = entreesFroides
cracoviePologne.localizedVersion = cracowPoland
cracowPoland.localizedVersion = cracoviePologne
desserts.localizedVersion = sweets
sweets.localizedVersion = desserts
entreesChaudes.localizedVersion = warmStarters
warmStarters.localizedVersion = entreesChaudes
plats.localizedVersion = main
main.localizedVersion = plats
poissons.localizedVersion = fish
fish.localizedVersion = poissons
innsbruckAutriche.localizedVersion = innsbruckAustria
innsbruckAustria.localizedVersion = innsbruckAutriche
lyonFrance.localizedVersion = lyonFrenchRepublic
lyonFrenchRepublic.localizedVersion = lyonFrance
viandes.localizedVersion = meat
meat.localizedVersion = viandes
nimesFrance.localizedVersion = nimesFrenchRepublic
nimesFrenchRepublic.localizedVersion = nimesFrance
portoPortugal.localizedVersion = portoPortugueseRepublic
portoPortugueseRepublic.localizedVersion = portoPortugal
poznanPologne.localizedVersion = poznanPoland
poznanPoland.localizedVersion = poznanPologne
pragueRepubliqueTcheque.localizedVersion = pragueCzechRepublic
pragueCzechRepublic.localizedVersion = pragueRepubliqueTcheque
saintGillesLaReunion.localizedVersion = stGillesReunionIsland
stGillesReunionIsland.localizedVersion = saintGillesLaReunion
saintLeuLaReunion.localizedVersion = stLeuReunionIsland
stLeuReunionIsland.localizedVersion = saintLeuLaReunion
saintPierreLaReunion.localizedVersion = stPierreReunionIsland
stPierreReunionIsland.localizedVersion = saintPierreLaReunion
strasbourgFrance.localizedVersion = strasbourgFrenchRepublic
strasbourgFrenchRepublic.localizedVersion = strasbourgFrance
toulonFrance.localizedVersion = toulonFrenchRepublic
toulonFrenchRepublic.localizedVersion = toulonFrance
vegetarien.localizedVersion = vegetarian
vegetarian.localizedVersion = vegetarien
venissieuxFrance.localizedVersion = venissieuxFrenchRepublic
venissieuxFrenchRepublic.localizedVersion = venissieuxFrance
vienneAutriche.localizedVersion = viennaAustria
viennaAustria.localizedVersion = vienneAutriche
naplesItalie.localizedVersion = naplesItaly
naplesItaly.localizedVersion = naplesItalie
marseilleFrance.localizedVersion = marseilleFrenchRepublic
marseilleFrenchRepublic.localizedVersion = marseilleFrance
stSuzanneLaReunion.localizedVersion = stSuzanneReunionIsland
stSuzanneReunionIsland.localizedVersion = stSuzanneLaReunion

// Create the list of loaded tags
const tags = [
    recettes, recipes,
    voyages, travel,
    famille, family,

    accompagnements, sides,
    boissons, beverages,
    boulanges, breadsAndDough,
    entrees, starters,
    entreesFroides, coldStarters,
    desserts, sweets,
    entreesChaudes, warmStarters,
    plats, main,
    poissons, fish,
    viandes, meat,
    vegetarien, vegetarian,

    aixLesBainsFrance, aixLesBainsFrenchRepublic,
    berneSuisse, bernSwitzerland,
    budapestHongrie, budapestHungary,
    chatelleraultFrance, chatelleraultFrenchRepublic,
    cracoviePologne, cracowPoland,
    innsbruckAutriche, innsbruckAustria,
    lyonFrance, lyonFrenchRepublic,
    nimesFrance, nimesFrenchRepublic,
    portoPortugal, portoPortugueseRepublic,
    poznanPologne, poznanPoland,
    pragueRepubliqueTcheque, pragueCzechRepublic,
    saintGillesLaReunion, stGillesReunionIsland,
    saintLeuLaReunion, stLeuReunionIsland,
    saintPierreLaReunion, stPierreReunionIsland,
    strasbourgFrance, strasbourgFrenchRepublic,
    toulonFrance, toulonFrenchRepublic,
    venissieuxFrance, venissieuxFrenchRepublic,
    vienneAutriche, viennaAustria,
    naplesItalie, naplesItaly,
    marseilleFrance, marseilleFrenchRepublic,
    stSuzanneLaReunion, stSuzanneReunionIsland
]

// Add slug methods
const completedTags = tags.map(tag => Object.assign(tag, methods))

exports.tagsData = completedTags
