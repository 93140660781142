exports.getPostUri = (node, lang) => {
    const publishedDate = new Date(node.published_at)
    const year = publishedDate.getFullYear()

    let month = publishedDate.getMonth()
    month += 1

    if (lang == null) {
        const isEnglishPost = node.tags.filter(tag => tag.visibility === `internal` && tag.slug === `hash-en`).length > 0
        lang = `${isEnglishPost ? `en` : `fr` }`
    }

    return `/${lang}/${year}/${String(month).padStart(2, 0)}/${node.slug}/`
}
