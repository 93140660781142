import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { getPostUri } from '../../../utils/post'

import { default as styles } from './RelatedPosts.module.css'

export const RelatedPosts = ({ posts }) => {
    const relatedPosts = posts
        .map(edge => edge.node)
        .sort(() => 0.5 - Math.random())
        .slice(0, 3)

    return (
        <section className={styles.container}>
            <h2 className={styles.title}>Related posts</h2>
            <ul className={styles.grid}>
                {relatedPosts.map((post, i) => {
                    const { title, feature_image: featureImage } = post
                    const slug = getPostUri(post)

                    return (
                        <li className={styles.gridItem} key={i}>
                            <Link to={slug}>
                                <div
                                    className={styles.thumbnail}
                                    style={{ backgroundImage: `url(${featureImage})` }}
                                />
                                {title}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </section>
    )
}

RelatedPosts.propTypes = {
    posts: PropTypes.arrayOf(
        PropTypes.shape({
            node: PropTypes.shape({
                title: PropTypes.string.isRequired,
                slug: PropTypes.string.isRequired,
                feature_image: PropTypes.string.isRequired,

            }),
        }),
    ),
}
