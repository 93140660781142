import React, { useContext, Fragment } from 'react'
import { shape, arrayOf, object } from 'prop-types'
import { Link } from 'gatsby'

import LocalizationContext, { LOCALES } from '../../LocalizationContext'
import { tagsMappings } from '../../../utils/tag'

import { default as styles } from './PostInfo.module.css'

export const PostInfo = (props) => {
    const { post } = props
    const { locale } = useContext(LocalizationContext)

    const shouldDisplayTags = (
        post.tags != null &&
        post.tags
            .filter(tag => tag.visibility === `public`)
            .length > 0
    )

    const tagsList = post.tags
        .filter(tag => tag.visibility === `public`)
        .map((tag, index) => {
            const mapping = tagsMappings.find(data => data.slug === tag.slug)

            return (
                <Fragment key={index}>
                    {index > 0 ? `, ` : null}
                    <Link to={`${mapping.uri()}`}>{tag.name}</Link>
                </Fragment>
            )
        })

    return (
        <>
            {locale === LOCALES.EN ? (
                <span className={styles.text}>
                    Posted {post.published_at_pretty}
                    {shouldDisplayTags ? (
                        <>&#x20;in {tagsList}</>
                    ) : null}
                    .
                </span>
            ) : null}
            {locale === LOCALES.FR ? (
                <span className={styles.text}>
                    Posté le {post.published_at_pretty}
                    {shouldDisplayTags ? (
                        <>&#x20;dans {tagsList}</>
                    ) : null}
                    .
                </span>
            ) : null}
        </>
    )
}

PostInfo.propTypes = {
    post: shape({
        tags: arrayOf(object).isRequired,
    }).isRequired,
}
