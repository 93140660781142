import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { DiscussionEmbed } from 'disqus-react'

import Layout from '../../components/blog/Layout'
import MetaData from '../../components/common/MetaData'
import LocalizationContext from '../../components/LocalizationContext'
import PostInfo from '../../components/blog/PostInfo'
import RelatedPosts from '../../components/blog/RelatedPosts'
import { getPostUri } from '../../utils/post'

import { default as styles } from './PostTemplate.module.css'

/**
* Single post view (/:lang/:year/:month/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const PostTemplate = ({ data, location, pageContext }) => {
    const post = data.ghostPost
    const relatedPosts = data.relatedPosts.edges

    return (
        <LocalizationContext.Provider value={{ locale: pageContext.language }}>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout pathname={location.pathname}>
                <div>
                    <article className="content">
                        <section className="post-full-content">
                            <h1 className={styles.contentTitle}>{post.title}</h1>

                            <div className={styles.postInfo}>
                                <PostInfo post={post} />
                            </div>

                            {post.feature_image ? (
                                <figure className={styles.featureImage}>
                                    <img src={ post.feature_image } alt={ post.title } />
                                </figure>
                            ) : null}

                            {/* The main post content */ }
                            <section
                                className={`${styles.contentBody} load-external-scripts`}
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                    <RelatedPosts posts={relatedPosts} />
                    <div style={{ margin: `40px 0` }}>
                        <DiscussionEmbed
                            shortname="travel-and-food"
                            config={{
                                url: `https://travel-and-food.com${getPostUri(post)}`,
                                identifier: post.comment_id,
                                title: post.title,
                            }}
                        />
                    </div>
                </div>
            </Layout>
        </LocalizationContext.Provider>
    )
}

PostTemplate.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
            codeinjection_styles: PropTypes.string,
            comment_id: PropTypes.string,
        }).isRequired,
        relatedPosts: PropTypes.shape({
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: PropTypes.shape({
                        title: PropTypes.string.isRequired,
                        slug: PropTypes.string.isRequired,
                        feature_image: PropTypes.string,
                    }),
                }).isRequired
            ),
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }),
}

export default PostTemplate

export const postQuery = graphql`
    query($slug: String!, $primaryTagSlug: String) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
        relatedPosts: allGhostPost(filter: { primary_tag: { slug: { eq: $primaryTagSlug }}}) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`
